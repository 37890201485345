// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notebook-5183-js": () => import("./../../../src/pages/notebook_5183.js" /* webpackChunkName: "component---src-pages-notebook-5183-js" */),
  "component---src-pages-sounds-js": () => import("./../../../src/pages/sounds.js" /* webpackChunkName: "component---src-pages-sounds-js" */),
  "component---src-pages-treefort-45001-js": () => import("./../../../src/pages/treefort_45001.js" /* webpackChunkName: "component---src-pages-treefort-45001-js" */)
}

